<template>
    <b-table striped hover :fields="fields" :items="row_data">
        <template v-slot:cell(action)="row">
            <b-button size="sm" @click="row.toggleDetails">
                {{ row.detailsShowing ? '還是不要看好了' : '看看備註' }}
            </b-button>
        </template>
        <template v-slot:row-details="row">
            <b-card>
                <p>備註：<br><span class="text-wrapper">{{row.item.remark}}</span></p>

            </b-card>
        </template>
    </b-table>
</template>

<script>
    import axios from "axios";

    export default {
        name: "UD-History",
        data: function () {
            return {
                uid: "",
                row_data: [
                ],
                fields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: "ID"
                    },
                    {
                        key: 'name4human',
                        sortable: false,
                        label: "床位"
                    },
                    {
                        key: 'stu_id',
                        sortable: false,
                        label: "當時的學號"
                    },
                    {
                        key: 'unit',
                        sortable: true,
                        label: "申請身份"
                    },
                    {
                        key: 'serial',
                        sortable: true,
                        label: "申請名單"
                    },
                    {
                        key: 'status',
                        sortable: false,
                        label: "目前狀態"
                    },
                    {
                        key: 'create_time',
                        sortable: true,
                        label: "登入時間"
                    },
                    {
                        key: 'checkin_time',
                        sortable: true,
                        label: "報到時間"
                    },
                    {
                        key: 'exp',
                        sortable: true,
                        label: "退房時間"
                    },
                    {
                        label: '備註',
                        key: 'action'
                    }
                ]
            }
        },
        mounted: function () {
            this.f5();
        },
        methods:{
            f5(){
                let url = '/api/booking/getHistoryByUser/' + this.$route.params.uuid;
                axios.get(url).then((response)=>{
                    let temp;
                    let output = [];
                    for(let item of response.data){
                        temp = Object.assign(item);
                        if (temp["exp"] !== null){
                            temp["exp"] = new Date(temp["exp"]).toLocaleString()
                        }
                        if (temp["checkin_time"] !== null){
                            temp["checkin_time"] = new Date(temp["checkin_time"]).toLocaleString()
                        }
                        if (temp["create_time"] !== null){
                            temp["create_time"] = new Date(temp["create_time"]).toLocaleString()
                        }
                        output.push(temp)
                    }
                    this.row_data = output
                })
            }
        },
        watch:{
            '$route' (){
                this.f5();
            }
        },
        beforeRouteUpdate () {
            this.f5();
        }
    }
</script>

<style scoped>
.text-wrapper {
    white-space: pre-wrap;
}
</style>